import { ActivatedRoute } from '@angular/router';
import { AdvanceConfirmComponent } from '../round/advance-confirm/advance-confirm.component';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { CloseRoundRequest, DirectCommunicationRequest, ScorecardImageUploadRequest, ScorecardViewRequest, TournamentAdvancementRequest } from '../../../interfaces';
import { CommunicationComponent } from '../../../communication/communication.component';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, map, startWith } from 'rxjs';
import { RoundRecapComponent } from '../round/round-recap/round-recap.component';
import { RoundService } from '../round/round.service';
import { ScorecardUploadComponent } from '../round/scorecard-upload/scorecard-upload.component';
import { SessionService } from 'src/app/tournament-backend/session.service';
import { Sort } from '@angular/material/sort';
import { TournamentService } from '../tournament.service';
import { UserServiceService } from '../../../user-service.service';

@Component({
  selector: 'app-round-results',
  templateUrl: './round-results.component.html',
  styleUrls: ['./round-results.component.css']
})
export class RoundResultsComponent implements OnInit {
  myControl = new FormControl();
  seriesId: number | null = null;
  roundId: number | null = null;
  public isLoading: boolean;
  public details: any;
  groupedRounds: any = {};

  filteredOptions: Observable<any[]> | undefined;
  paginatedIndividualCompetitors: any[] = [];
  filteredCompetitors: any[] | null = null;

  @ViewChild(MatPaginator) paginator!: MatPaginator;


  constructor(private route: ActivatedRoute, private service: TournamentService, private userService: UserServiceService,
    public dialog: MatDialog, private cd: ChangeDetectorRef, public roundService: RoundService, private _snackBar: MatSnackBar,
    private sessionService: SessionService) {
    this.isLoading = true
  }

  verifyAccess(roleRequired: string) {
    return this.userService.verifyUserRole(roleRequired);
  }

  ngOnInit() {
    this.isLoading = true
    this.route.paramMap.subscribe(params => {
      const seriesIdParam = params.get('seriesId');
      const roundIdParam = params.get('roundId');
      if (seriesIdParam !== null) {
        this.seriesId = parseInt(seriesIdParam);
        if (isNaN(this.seriesId)) {
          this.seriesId = null;
        }
      }

      if (roundIdParam !== null) {
        this.roundId = parseInt(roundIdParam);
        if (isNaN(this.roundId)) {
          this.roundId = null;
        } else {
          this.fetchDetails(this.roundId);
        }
      }

    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''), // Initialize the stream with an empty string
      map(value => this._filter(value)) // Apply the filtering logic
    );

  }

  private _filter(val: string): any[] {
    const filterValue = val.toLowerCase();

    // Ensure this.details.individualCompetitors is an array
    const competitors = this.details?.individualCompetitors || [];

    if (!val) {
      return competitors;
    } else {
      return competitors.filter((individual: any) =>
        individual.playersEmail.toLowerCase().includes(filterValue)
      );
    }
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.updatePagination();
      });
    }
  }

  filterCompetitors(val: string | null) {
    if (!val) {
      // Reset to the original full list
      this.filteredCompetitors = null;
      this.updatePagination();
    } else {
      // Filter the list based on the email
      this.filterAndPaginateCompetitors(val);
    }
  }

  resetSearch() {
    // Clear the search input
    this.myControl.setValue('');

    // Reset the filtered list
    this.filteredCompetitors = null;

    // Reset the pagination to show the full list
    this.updatePagination();

    // If using a paginator, reset it to the first page
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }

  filterAndPaginateCompetitors(email: string) {
    this.filteredCompetitors = this.details.individualCompetitors.filter((individual: any) =>
      individual.playersEmail.toLowerCase() === email.toLowerCase()
    );
    if (this.paginator) {
      this.paginator.firstPage();
    }
    this.updatePagination();
  }


  onEmailSelect(event: MatAutocompleteSelectedEvent) {
    const selectedEmail = event.option.value;
    this.filterAndPaginateCompetitors(selectedEmail);
  }


  sortData(sort: Sort) {
    console.log("sorting this?", sort)
    const data = this.paginatedIndividualCompetitors.slice();
    if (!sort.active || sort.direction === '') {
      this.paginatedIndividualCompetitors = data;
      return;
    }
    // Sorting the tournamentRounds by score for each golfer
    data.forEach(golfer => {
      if (golfer.tournamentRounds && golfer.tournamentRounds.length > 0) {
        golfer.tournamentRounds.sort((round1: any, round2: any) => {
          return sort.direction === 'asc' ? round1.lowestRoundScore - round2.lowestRoundScore : round2.lowestRoundScore - round1.lowestRoundScore;
        });
      }
    });
    this.paginatedIndividualCompetitors = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'email':
          return this.compare(a.playersEmail, b.playersEmail, isAsc);
        case 'date':
          // Special sorting of tournamentRounds by date when sorting by date
          if (a.tournamentRounds && a.tournamentRounds.length > 0) {
            a.tournamentRounds.sort((round1: any, round2: any) => {
              const date1 = new Date(round1.created).getTime();
              const date2 = new Date(round2.created).getTime();
              return isAsc ? date1 - date2 : date2 - date1; // Order by date
            });
          }
          if (b.tournamentRounds && b.tournamentRounds.length > 0) {
            b.tournamentRounds.sort((round1: any, round2: any) => {
              const date1 = new Date(round1.created).getTime();
              const date2 = new Date(round2.created).getTime();
              return isAsc ? date1 - date2 : date2 - date1; // Order by date
            });
          }
          // Get the most recent or earliest date for comparison
          const aRecentDate = (a.tournamentRounds && a.tournamentRounds.length > 0) ? new Date(a.tournamentRounds[0].created) : new Date(0);
          const bRecentDate = (b.tournamentRounds && b.tournamentRounds.length > 0) ? new Date(b.tournamentRounds[0].created) : new Date(0);
          return this.compare(aRecentDate, bRecentDate, isAsc);
        case 'round':
          return this.compare(a.lowestRoundScore, b.lowestRoundScore, isAsc);
        //const aLowestScore = a.tournamentRounds && a.tournamentRounds.length > 0
        //  ? a.tournamentRounds[0].lowestRoundScore
        //  : (isAsc ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER); // Use max or min safe integer if no rounds

        //// Find the lowest round score for golfer b
        //const bLowestScore = b.tournamentRounds && b.tournamentRounds.length > 0
        //  ? b.tournamentRounds[0].lowestRoundScore
        //  : (isAsc ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER); // Use max or min safe integer if no rounds

        //return this.compare(aLowestScore, bLowestScore, isAsc);
        //case 'carbs':
        //  return this.compare(a.carbs, b.carbs, isAsc);
        //case 'protein':
        //  return this.compare(a.protein, b.protein, isAsc);
        default:
          return 0;
      }
    });
  }

  // Utility function to compare values (used in sorting)
  compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    // Convert string values to lowercase for case-insensitive comparison
    if (typeof a === 'string' && typeof b === 'string') {
      a = a.toLowerCase();
      b = b.toLowerCase();
    }

    if (a < b) {
      return isAsc ? -1 : 1;
    } else if (a > b) {
      return isAsc ? 1 : -1;
    } else {
      return 0;
    }
  }



  async fetchDetails(id: number): Promise<any> {
    if (id > 0) {
      this.service.getRound(id).subscribe((x: any) => {
        this.details = x;
        this.groupedRounds = this.groupByPlayer(this.details?.individualCompetitors);
      }, (err: any) => {
        console.log(err);
      }, () => {
        this.updatePagination();
        this.isLoading = false;
      });

    }
  }

  groupByPlayer(players: any[]): { [key: string]: any[] } {
    const grouped: { [key: string]: any[] } = {};

    players.forEach(player => {
      const email = player.playersEmail;
      if (!grouped[email]) {
        grouped[email] = [];
      }
      grouped[email].push(player);
      grouped[email].push(player);
    });

    // Sort rounds for each player by score
    for (let email in grouped) {
      grouped[email].sort((a, b) => {
        // Define default score values
        const defaultScore = 0;

        // Extract the scores, or use default if roundScoreTotal or golfRoundData is undefined
        const scoreA = a.golfRoundData?.roundScoreTotal ?? defaultScore;
        const scoreB = b.golfRoundData?.roundScoreTotal ?? defaultScore;

        // Perform the comparison
        return scoreA - scoreB;
      });
    }



    return grouped;
  }

  updatePagination() {
    let currentList = this.filteredCompetitors || this.details.individualCompetitors;
    if (this.paginator && currentList) {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      const endIndex = startIndex + this.paginator.pageSize;
      // Initialize an empty array to hold unique items
      if (this.details.tournamentRoundRequest.isFinalRound) {
        let uniqueList: any[] = [];
        // Loop through each entry in the currentList and add it if the `tournamentRounds` value isn't already present
        currentList.forEach((item: any) => {
          // Check if both `tournamentRounds` and `playersSyncId` already exist in the unique list
          const isDuplicate = uniqueList.some(existingItem =>
            existingItem.playersSyncId === item.playersSyncId
          );
          // Add item to the uniqueList only if it's not a duplicate
          if (!isDuplicate) {
            if (item.tournamentRounds != null) {
              uniqueList.push(item);
            }
          }
        });

        // Slice the unique list based on pagination indices
        this.paginatedIndividualCompetitors = uniqueList.slice(startIndex, endIndex);
      } else {
        this.paginatedIndividualCompetitors = currentList.slice(startIndex, endIndex)
      }
      // Trigger change detection
      this.cd.markForCheck();
    }
  }

  openScorecardUploadScreen(round: any, individual: any) {
    //check here what type of round it is before moving on
    const request: ScorecardImageUploadRequest = {
      file: null,
      competitorId: individual.seriesCompetitorId,
      email: individual.playersEmail,
      tournamentRoundId: this.details.tournamentRoundRequest.id,
      referenceId: round.roundSyncId,
      teamCard: this.details.tournamentRoundRequest.isTeamEvent,
      userId: individual.userId,
      userSyncId: individual.playersSyncId,
      seriesTournamentId: this.details.tournamentRoundRequest.tournamentId,
      location: individual.playedLocation,
      seriesId: this.seriesId ? this.seriesId : 0,
      simProvidedScore: round.simProvidedRoundScore
    }
    const dialogRef = this.dialog.open(ScorecardUploadComponent, {
      data: request
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading = false
        const individualCompetitor = this.details?.individualCompetitors?.find((competitor: any) => {
          return competitor?.tournamentRounds?.find((round: any) => round?.roundSyncId === result.roundSyncId);
        });
        if (individualCompetitor) {
          const roundFound = individualCompetitor.tournamentRounds.find((round: any) =>
            round.roundSyncId === request.referenceId
          );
          individualCompetitor.scorecardImageURL = result.scorecardImageURL
          individualCompetitor.roundSyncId = result.roundSyncId
          individualCompetitor.recognizedCompetitor = true
          individualCompetitor.userId = result.userId
          roundFound.scorecardImageURL = result.scorecardImageURL
          individualCompetitor.seriesCompetitorId = result.seriesCompetitorId
        }
        //this.cd.markForCheck()
      }
    })
  }

  hasVerifiedScorecard(individual: any): boolean {
    const roundFound = individual.tournamentRounds.find((round: any) =>
      round.scorecardVerifiedBy !== '' && round.scorecardVerifiedBy !== null
    );

    return !!roundFound;  // This will return true if a round is found and false otherwise.
  }

  sortHoleScores(round: any): { key: number, value: any }[] {
    return Object.entries(round.roundScores)
      .map(([key, value]) => ({ key: +key, value }))
      .sort((a, b) => a.key - b.key);
  }

  visibleToLM(individual: any): boolean {
    if (!individual) {
      return false;
    }
    if (individual.playedLocation) {
      const hasMatch = this.details.locations.some((location: any) => {
        return location?.name === individual.playedLocation;
      });
      return hasMatch;
    }

    if (individual.tournamentRounds) {
      const hasMatchingLocation = individual.tournamentRounds.some((round: any) =>
        round?.location && this.details.locations.some((location: any) =>
          location?.name === round.location
        )
      );
      return hasMatchingLocation;
    }
    return false
  }

  viewCard(individual: any, round: any) {
    const start = individual.scorecardVerified
    const request: ScorecardViewRequest = {
      scoreCards: [],
      gameSessionExternalKey: round.gameSessionExternalKey,
      scorecardImageURL: round.scorecardImageURL,
      competitorId: individual.userId,
      email: individual.playersEmail,
      tournamentRoundId: this.details.tournamentRoundRequest.id,
      referenceId: round.roundSyncId,
      teamCard: this.details.tournamentRoundRequest.isTeamEvent,
      userId: individual.userId,
      userSyncId: individual.playersSyncId,
      seriesTournamentId: this.details.tournamentRoundRequest.tournamentId,
      location: individual.playedLocation,
      seriesId: this.seriesId ? this.seriesId : 0,
      course: this.details.course,
      roundScores: round?.roundScores || {},
      totalNumberOfStrokes: round?.roundScoreTotal || 0,
      created: round.created,
      netRoundOnly: this.details.netOnly,
      allStrokes: round?.allStrokes,
      roundVerified: individual.scorecardVerified,
      roundNetScore: round.roundNetScore,
      simProvidedScore: round.simProvidedRoundScore,
      startingHandicap: round.startingHandicap,
      overrideScore: individual.overrideScore,
      messageArea: round.messageArea,
      //this is the round that was submitted as part of the tournament round
      advancingRoundSyncId: individual.roundSyncId,
      displayName: individual.displayName,
      finalRound: this.details.isFinalRound
    };

    this.sessionService.getGameSessionScoreCards(request.gameSessionExternalKey)
      .subscribe(response => {
        // console.log("response: ", JSON.stringify(response, null, 2));
        const scoreCards = response.length > 0 ? response : [{ url: "/lib/angular/dist/assets/xga-logo.jpg" }];
        // console.log("scorecards: ", JSON.stringify(scoreCards, null, 2));
        request.scoreCards = scoreCards;
        // console.log("request.scorecards: ", JSON.stringify(request.scoreCards, null, 2));
        const dialogRef = this.dialog.open(RoundRecapComponent, {
          data: request,
        });

        // const dialogRef = this.dialog.open(RoundRecapComponent, {
        //   data: request
        // })
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            const individualCompetitor = this.details?.individualCompetitors?.find((competitor: any) => {
              return competitor?.tournamentRounds?.find((round: any) => round?.roundSyncId === result.referenceId);
            });
            //check for update by saying: if we starting with not verified and then got verified we need to update that individual info
            if (start == false && result.roundVerified) {
              individualCompetitor.roundSyncId = request.referenceId
              individualCompetitor.seriesCompetitorId = result.competitorId
              individualCompetitor.scorecardVerified = result.roundVerified
              individualCompetitor.simProvidedScore = result.simProvidedScore
              individualCompetitor.startingHandicap = result.startingHandicap
              individualCompetitor.overrideScore = result.overrideScore
              individualCompetitor.overrideScoreMessage = result.messageArea
              individualCompetitor.netScore = result.roundNetScore
              if (individualCompetitor) {
                const roundFound = individualCompetitor.tournamentRounds.find((round: any) =>
                  round.roundSyncId === request.referenceId
                );
                if (roundFound) {
                  roundFound.roundNetScore = result.roundNetScore
                  roundFound.roundGrossScore = result.roundGrossScore
                  roundFound.messageArea = result.messageArea
                  roundFound.startingHandicap = result.startingHandicap
                  roundFound.simProvidedRoundScore = result.simProvidedScore
                  roundFound.scorecardImageURL = result.scorecardImageURL
                  roundFound.scorecardVerifiedBy = "justverifieidamomentagonotrequiredrightnow"
                  roundFound.scorecardVerifiedOn = new Date()
                }
              }

            }

            this.isLoading = false
            //this.cd.markForCheck()
          }
        })
      })
  }

  openAdvancementScreen(individual: any) {
    var previousRound = this.previousRoundsPlayedLocation(individual)
    const request: TournamentAdvancementRequest = {
      individual: individual,
      nextRounds: this.details.tournamentRoundRequest.nextTournaments,
      tournamentId: this.details.tournamentRoundRequest.tournamentId,
      pastRoundPlayedLocation: previousRound.location
    }
    const dialogRef = this.dialog.open(AdvanceConfirmComponent, {
      data: request
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const individualCompetitor = this.details?.individualCompetitors?.find((x: any) => {
          return x.seriesCompetitorId === individual.seriesCompetitorId;
        });
        if (individualCompetitor) {
          individualCompetitor.advancing = true

        }
        this.cd.markForCheck()
      }
    }, err => { console.log(err) }, () => { })
  }

  previousRoundsPlayedLocation(individual: any): any {
    const roundFound = individual.tournamentRounds.find((round: any) =>
      round.scorecardVerifiedBy !== '' && round.scorecardVerifiedBy !== null
    );
    return roundFound;  // This will return true if a round is found and false otherwise.
  }

  convertHandicap(handicap: number): string {
    // Your logic to convert the handicap here, for instance:
    return handicap < 0 ? `+${-handicap}` : handicap.toString();
  }

  openComms(email: string) {

    var request: DirectCommunicationRequest = {
      emailTo: email,
      emailFrom: '',
      message: ''
    }

    this.dialog.open(CommunicationComponent, {
      data: request
    })
  }


  closeRound(round: any) {
    // Show confirmation alert
    if (!window.confirm('Are you sure you want to close this round?')) {
      return;
    }
    this.isLoading = true;
    const request: CloseRoundRequest = {
      tournamentId: round.tournamentId,
      roundId: round.id,
      isFinal: round.isFinal,
      userMakingRequest: '',
      isTeamEvent: round.isTeamEvent
    }
    this.roundService.postCloseRound(request).subscribe(x => {
      if (x) {
        this.details.tournamentRoundRequest.closed = x
        this._snackBar.open('Thats A Wrap! Round Has Been Closed!.', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "successNotice"
        });
        this.isLoading = false
      }
    }, err => { console.log(err) }, () => { })

  }
}
