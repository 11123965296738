import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    constructor(private http: HttpClient) { }

    getGameSessionScoreCards(gameSessionExternalKey: string): Observable<any> {
        return this.http.get<any>(`api/session/${gameSessionExternalKey}/scorecard`, { } );
      }
}
