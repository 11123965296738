<div *ngIf="!isLoading" class="container p-1" style="width:100vw;height: 100vh; overflow-y: scroll;overflow-x:hidden;">
  <div class="row">
    <div class="col-md-12 center">
      <h3 class="display-3">ROUND RECAP</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="p-3">
        <h4>ROUND INFO:</h4>
        <p>Players Email: <a (click)="openComms(details.email)">{{details.email}}</a></p>
        <p>Display Name: <strong style="font-size:1.2em">{{details.displayName}}</strong></p>
        <p>Round Finished: <strong>{{details.created | date:'short'}}</strong></p>
        <p>Played At: <strong>{{details.location}}</strong> </p>
          <button *ngIf="details.scorecardImageURL != '' && (verifyAccess('TournamentAdmin') || verifyAccess('SystemAdmin'))" class="btn btn-sm btn-danger" (click)="discardRound(details)">DISCARD</button>
      </div>
      <div class="center p-3" *ngIf="details.scorecardImageURL != ''">
        <img src="{{details.scorecardImageURL}}" class="img-fluid" />
      </div>
      <div class="center p-3" *ngFor="let scoreCard of details.scoreCards">
        <img src="{{scoreCard.url}}" class="img-fluid" />
      </div>
    </div>
    <div class="col-md-12">
      <div class="mb-4">
        <h4 style="text-align:center;">{{details.course.title}}</h4>
        <div class="center">
          <div style="width: 50px; border-right: black solid 1px; border-top: 1px solid black; border-left: black solid 1px; display: inline-table; ">
            <div style="height: 50px; width:80px; padding: 9px; border-bottom: black solid 1px; text-align: center ">Hole</div>
            <div style="height: 50px; width: 80px; padding: 9px; border-bottom: black solid 1px; text-align: center ">Par</div>
            <div style="height: 50px; width: 80px; padding: 9px; text-align: center; border-bottom: 1px solid black;">Score</div>
          </div>
          <ng-container *ngFor="let hole of sortedHoleScores">
            <div style="width: 50px; border-right: black solid 1px; border-top: 1px solid black; border-left: black solid 1px; display: inline-table; ">
              <div style="height: 50px; border-bottom: black solid 1px; text-align: center; padding: 6px">{{ hole.key }}</div>
              <div style="height: 50px; border-bottom: black solid 1px; text-align: center; padding: 9px">{{ getParForHole(hole.key) }}</div>
              <div style="height: 50px; text-align: center; border-bottom: 1px solid black; padding: 9px">
                <ng-container *ngIf="hole.value < getParForHole(hole.key) - 1">
                  <div class="bird-double-circle">
                    <div class="bird-circle">
                      <div class="inner-circle">
                        {{ hole.value }}
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="hole.value === getParForHole(hole.key) - 1">
                  <span class="bird-circle">{{ hole.value }}</span>
                </ng-container>
                <ng-container *ngIf="hole.value === getParForHole(hole.key)">
                  <span>{{ hole.value }}</span>
                </ng-container>
                <ng-container *ngIf="hole.value === getParForHole(hole.key) + 1">
                  <span class="boggy-square">{{ hole.value }}</span>
                </ng-container>
                <ng-container *ngIf="hole.value === getParForHole(hole.key) + 2">
                  <div class="">
                    <div class="outter-square">
                      <div class="boggy-square">
                        {{ hole.value }}
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="hole.value > getParForHole(hole.key) + 2">
                  <span>{{ hole.value }}</span>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <div style="
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 25px;
      margin-left:25px;
      font-weight: bold;
      font-size: 2.7em;
      border: 2px solid black;
      border-radius: 50%;
      width: 75px;
      height: 75px;
      text-align: center;">
            {{ details.totalNumberOfStrokes }}
          </div>
        </div>
      </div>

      <div style="max-height:400px;overflow-y:scroll;overflow-x:hidden;border:2px solid black;padding:16px;">
        <table style="width:100%; border-collapse:collapse;">
          <thead class="sticky-top bg-white" style="font-size: .75em; top: 0; z-index: 1000;">
            <tr style="background-color:yellow;">
              <th colspan="12" style="text-align:left;">* MULLIGAN</th>
            </tr>
            <tr class="sticky-top bg-white" style="font-size: .75em; top: 0; z-index: 1000;">
              <th>HOLE</th>
              <th>STROKE</th>
              <th>CLUB</th>
              <th>FROM - TO</th>
              <th>DISTANCE</th>
              <th>REMAINING TO FLAG</th>
              <th>CLUB SPEED (MPH)</th>
              <th>BALL SPEED (MPH)</th>
              <th>CLUB IMPACT ANGLE</th>
              <th>BALL LAUNCH ANGLE</th>
              <th>BACK SPIN</th>
              <th>SIDE SPIN</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of details.allStrokes; let i = index">
              <tr *ngIf="i > 0 && item.holeNumber > details.allStrokes[i - 1].holeNumber">
                <td colspan="12"><hr /></td>
              </tr>
              <tr [ngStyle]="{'background-color': item.strokeType == 'MULLIGAN' ? 'yellow' : 'inherit'}">
                <td>
                  <ng-container *ngIf="i === 0 || item.holeNumber !== details.allStrokes[i - 1].holeNumber; else emptySpace">
                    <span style="font-weight: bold; font-size: 1.5em">{{ item.holeNumber }}</span>
                  </ng-container>
                  <ng-template #emptySpace></ng-template>
                </td>
                <td>{{ item.strokeNumber }}</td>
                <td>{{ item.club }}</td>
                <td><span style="font-size:.75em;">{{ item.from }}-{{ item.to }}</span></td>
                <td>{{ item.totalDistanceInYards | number:'1.2-2' }}</td>
                <td>{{ item.distanceRemaining !== 'HOLE' ? (item.distanceRemaining | number:'1.2-2') : '' }}</td>
                <td>{{ item.clubSpeedMilesPerHour | number:'1.2-2' }}</td>
                <td>{{ item.ballSpeedMilesPerHour | number:'1.2-2' }}</td>
                <td>{{ item.impactAngle | number:'1.2-2' }}</td>
                <td>{{ item.launchAngle | number:'1.2-2' }}</td>
                <td>{{ item.backSpin | number:'1.2-2' }}</td>
                <td>{{ item.sideSpin | number:'1.2-2' }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>


    </div>
  </div>


  <div *ngIf="(verifyAccess('TournamentAdmin') || verifyAccess('SystemAdmin')) && (details.advancingRoundSyncId === 0 || details.advancingRoundSyncId === details.referenceId)" class="row center">
    <form class="row" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group m-2">
        <ng-container *ngIf=""></ng-container>
        <label for="simProvidedScore">GROSS SCORE PROVIDED BY SIM</label>
        <input type="number"
               class="form-control form-control-sm"
               formControlName="simProvidedScore"
               style="width: 50px; display:inline;margin-left:16px" />
      </div>
      <div class="form-group m-2">
        <label for="validationInput">CONFIRM SCORE</label>
        <input type="number"
               class="form-control form-control-sm"
               formControlName="validationInput"
               style="width: 50px; display:inline;margin-left:16px" />
      </div>
      <div class="form-group m-2">
        <label>Reason For Score Difference</label>
        <input class="form-control"
               formControlName="messageArea"
               placeholder="Reason for changing score." />
      </div>

      <div *ngIf="(this.details.isFinalRound && this.details.netRoundOnly) || !this.details.isFinalRound" class="form-group">
        <label>HANDICAP PRE ROUND</label>
        <input type="text" class="form-control form-control-sm"
               formControlName="netStartingHandicap"
               style="width: 50px; display:inline;margin-left:16px" />
      </div>
      <div *ngIf="(this.details.isFinalRound && this.details.netRoundOnly) || !this.details.isFinalRound" class="form-group m-2">
        <label>NET SCORE</label>
        <input type="number" class="form-control form-control-sm"
               formControlName="netScoreInput"
               style="width: 50px; display:inline;margin-left:16px" />
      </div>
      <button class="btn btn-sm xbtn-primary" type="submit" [disabled]="details.roundVerified">Validate</button>
      <!--if details.netOnly we need to make sure the handicaps are entered before allowing submit-->
    </form>


  </div>
</div>


<div *ngIf="isLoading" class="container">
  <div class="row" style="display:flex;justify-content:center;margin-top:13%">
    <div class="col-sm-12" style="height: 200px; width: 200px;overflow:hidden;">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>
