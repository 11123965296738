import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DirectCommunicationRequest, ScoreValidationRequest, ScorecardViewRequest } from '../../../../interfaces';
import { map } from 'rxjs';
import { RoundService } from '../round.service';
import { UserServiceService } from '../../../../user-service.service';
import { CommunicationComponent } from '../../../../communication/communication.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-round-recap',
  templateUrl: './round-recap.component.html',
  styleUrls: ['./round-recap.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoundRecapComponent implements OnInit {
  public isLoading: boolean;
  public sortedHoleScores: { key: number, value: any }[];
  public details: any;
  form: FormGroup;
  public roundData: any;

  constructor(private fb: FormBuilder, public roundService: RoundService, private _snackBar: MatSnackBar,
    private router: Router, private route: ActivatedRoute, private userService: UserServiceService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ScorecardViewRequest, public dialog: MatDialog, private dialogRef: MatDialogRef<RoundRecapComponent>,
    private cd: ChangeDetectorRef) {

    this.isLoading = true
    this.details = this.data
    const subscription = this.dialogRef.beforeClosed().subscribe(() => {
      subscription.unsubscribe();  // Unsubscribe before closing the dialog
      this.dialogRef.close(this.details);
    });

    var formated = this.formatHandicap(this.details.startingHandicap)

    this.form = this.fb.group({
      netStartingHandicap: [this.details.startingHandicap >= 0 ? this.details.startingHandicap : formated],
      netScoreInput: [this.details.roundNetScore ? this.details.roundNetScore : 0],
      simProvidedScore: [this.details.totalNumberOfStrokes],
      validationInput: [this.details.overrideScore ? this.details.overrideScore : 0],
      messageArea: [{ value: this.details.messageArea ? this.details.messageArea : '', disabled: true }],
    });
    this.form.get('validationInput')?.valueChanges.subscribe(val => {
      const input1Value = this.form.get('simProvidedScore')?.value;
      if (val !== input1Value) {
        this.form.get('messageArea')?.enable();
      } else {
        this.form.get('messageArea')?.disable();
      }
    });
    this.form.get('simProvidedScore')?.disable();

    this.details = this.data
    if (this.details.roundVerified) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this.sortedHoleScores = this.sortHoleScores(this.details)
  }

  formatHandicap(handicap: number): string {
    if (handicap < 0) {
      return '+' + (-handicap); // If negative, remove the minus sign and prepend a plus sign.
    }
    return handicap !== undefined ? handicap.toString() : ""; // If zero or positive, return as is.
  }


  verifyAccess(roleRequired: string): boolean {
    return this.userService.verifyUserRole(roleRequired);
  }

  ngOnInit(): void {
    this.isLoading = false
    this.cd.markForCheck();
  }

  sortHoleScores(round: any): { key: number, value: any }[] {
    return Object.entries(round.roundScores)
      .map(([key, value]) => ({ key: +key, value }))
      .sort((a, b) => a.key - b.key);
  }

  getParForHole(holeNumber: number): number {
    const hole = this.details.course.courseHoles.find((h:any) => h.holeNumber === holeNumber);
    return hole ? hole.par : 0;
  }

  onSubmit() {
    this.isLoading = true;

    this.cd.markForCheck();
    var request: ScoreValidationRequest = {
      referenceId: this.details.referenceId,
      simProvidedTotalNumberOfStrokes: this.details.simProvidedScore,
      overrideRoundScore: this.form.controls['validationInput'].value,
      overrideMessage: this.form.controls['messageArea'].value,
      validatedBy: '',
      netScore: this.form.controls['netScoreInput'].value,
      grossScore: 0,
      startingHandicap: this.form.controls['netStartingHandicap'].value,
      roundId: this.details.tournamentRoundId,
      tournamentId: this.details.seriesTournamentId,
      userId: this.details.userId,
      userSyncId: this.details.userSyncId,
      location: this.details.location,
      seriesId: this.details.seriesId,
      email: this.details.email
    }

    this.roundService.postValidateIndividualCompetitorsRound(request).subscribe((x: any) => {
      this.form.controls['netScoreInput'].setValue(x.roundNetScore)
      this.form.controls['netStartingHandicap'].setValue(x.startingHandicap)
      this.form.disable()
      this.details.roundVerified = true
      this.details.competitorId = x.seriesCompetitorId
      this.details.roundNetScore = x.roundNetScore
      this.details.simProvidedScore = x.simProvidedRoundScore
      this.details.overrideScore = x.roundGrossScore
      this.details.messageArea = x.roundScoreOverrideMessage
      this.details.startingHandicap = x.startingHandicap
      this.isLoading = false
      this._snackBar.open('Scorecard verified.', '', {
        duration: 3000,
        verticalPosition: "bottom",
        horizontalPosition: "right",
        panelClass: "successNotice"
      });
      this.cd.markForCheck();
    }, err => { }, () => { })
  }

  openComms(email: string) {
    var request: DirectCommunicationRequest = {
      emailTo: email,
      emailFrom: '',
      message: ''
    }

    this.dialog.open(CommunicationComponent, {
      data: request
    })
  }

  discardRound(roundData: any) {
    if (!window.confirm('Are you sure you want to discard this round?')) {
      return;
    }
    this.isLoading = true;
    this.roundService.postDiscardRound(roundData.referenceId).subscribe(x => {
      if (x) {
        this._snackBar.open('Scorecard discarded. Please refresh page', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "successNotice"
        });
        if (!window.confirm('You have discarded the round, would like to refresh now?')) {
          return;
        }
        window.location.reload()
      }
    }, err => { }, () => {

    })
  }

}
