import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Optional, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TournamentService } from '../tournament.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserServiceService } from '../../../user-service.service';
import { ScorecardUploadComponent } from './scorecard-upload/scorecard-upload.component';
import { BestBall_TeamAdvancementRequest, CloseRoundRequest, CreateTeamRoundRequest, DirectCommunicationRequest, LeaderboardOnRequest, ScorecardImageUploadRequest, ScorecardViewRequest, SeriesTournamentTeamCompetitorRound, TeamAdvancementRequest, TeamScorecardImageUploadRequest, TeamScorecardViewRequest, TournamentAdvancementRequest, TournamentRoundViewRequest } from '../../../interfaces';
import { RoundRecapComponent } from './round-recap/round-recap.component';
import { AdvanceConfirmComponent } from './advance-confirm/advance-confirm.component';
import { CommunicationComponent } from '../../../communication/communication.component';
import { RoundService } from './round.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TeamRoundRecapComponent } from './team-round-recap/team-round-recap.component';
import { TeamScorecardUploadComponent } from './team-scorecard-upload/team-scorecard-upload.component';
import { ManualRoundEntryComponent } from './manual-round-entry/manual-round-entry.component';
import { TeamAdvancementConfirmComponent } from './team-advancement-confirm/team-advancement-confirm.component';
import { TeamBestBallAdvancementConfirmComponent } from './team-best-ball-advancement-confirm/team-best-ball-advancement-confirm.component';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { SessionService } from 'src/app/tournament-backend/session.service';

@Component({
  selector: 'app-round',
  templateUrl: './round.component.html',
  styleUrls: ['./round.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoundComponent implements OnInit {
  @ViewChildren(MatPaginator)
  paginatorQueryList!: QueryList<MatPaginator>;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  public isLoading: boolean;
  public details: any;
  public dialoging: boolean;
  locations: any[] = [];
  groupedRounds: any = {};

  paginatedIndividualCompetitors: any[] = [];
  paginatedData: any[] | undefined; // Data slice to display in the template



  constructor(private route: ActivatedRoute, private router: Router, private service: TournamentService,
    private userService: UserServiceService, private cd: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: TournamentRoundViewRequest,
    public dialog: MatDialog, private dialogRef: MatDialogRef<RoundComponent>, public roundService: RoundService, private _snackBar: MatSnackBar,
    private sessionService: SessionService) {

    this.isLoading = true
    this.dialoging = true;
  }

  verifyAccess(roleRequired: string) {
    return this.userService.verifyUserRole(roleRequired);
  }
  paginateIndividuals() {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;
    this.paginatedIndividualCompetitors = this.details?.individualCompetitors.slice(startIndex, endIndex);
    console.log("this last bit", this.paginatedIndividualCompetitors)
  }

  paginateData() {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;
    this.paginatedData = []
  }

  ngAfterViewInit() {
    console.log("after view int")
    this.paginatorQueryList.changes.subscribe(() => {
      this.updatePagination();
    });
    this.updatePagination();

  }

  private subscribeToPaginator(paginator: MatPaginator) {
    paginator.page.subscribe(() => {
      this.updatePagination();
    });
  }

  updatePagination() {
    const paginator = this.paginatorQueryList.first;
    if (paginator && this.details?.individualCompetitors) {
      const startIndex = paginator.pageIndex * paginator.pageSize;
      const endIndex = startIndex + paginator.pageSize;
      this.paginatedIndividualCompetitors = this.details.individualCompetitors.slice(startIndex, endIndex);
    }
  }

  sortData(sort: Sort) {
    const data = this.details.individualCompetitors.slice();
    if (!sort.active || sort.direction === '') {
      this.paginatedIndividualCompetitors = data;
      return;
    }

    this.paginatedIndividualCompetitors = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'email':
          return this.compare(a.name, b.name, isAsc);
        //case 'calories':
        //  return this.compare(a.calories, b.calories, isAsc);
        //case 'fat':
        //  return this.compare(a.fat, b.fat, isAsc);
        //case 'carbs':
        //  return this.compare(a.carbs, b.carbs, isAsc);
        //case 'protein':
        //  return this.compare(a.protein, b.protein, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ngOnInit() {
    if (this.data) {
      console.log("hello", this.data)
      this.dialoging = false;
      this.fetchDetails(this.data.round.id);
      
    } else {
      // Opened as a standalone page
      console.log("this is a route")
      this.route.paramMap.subscribe(params => {
        const idInRoute = Number(params.get('roundId'));
        if (idInRoute) {
          console.log("going to try and get round now ")
          this.dialoging = false;
          //you need to remove all this  i dont remember who we get here just yet.
          this.service.getRound(idInRoute).subscribe(x => {
            
            this.details = x;
            console.log(this.details)
            this.groupedRounds = this.groupByPlayer(this.details?.individualCompetitors);

            this.isLoading = false;
          }, err => {
            console.log(err);
          });
        }
      }, err => {
        console.log(err);
      });
    }
  }

  fetchDetails(id: number) {
    if (id > 0) {
      this.service.getRound(id).subscribe(x => {
        this.details = x;
        // console.log("details after fetch", x)
        this.isLoading = false;
        this.groupedRounds = this.groupByPlayer(this.details?.individualCompetitors);
      }, err => {
        console.log(err);
      }, () => {
        this.cd.markForCheck()
      });

    }
  }

  indyCanAdvance(rounds: any[]): boolean {
    return rounds.some(round =>
      round.scorecardImageURL != null && round.scorecardVerifiedBy != null
    );
  }

  visibleToLM(individual: any): boolean {
    if (!individual) {
      return false;
    }
    if (individual.playedLocation) {
      const hasMatch = this.details.locations.some((location: any) => {
        return location?.name === individual.playedLocation;
      });
      return hasMatch;
    }

    if (individual.tournamentRounds) {
      const hasMatchingLocation = individual.tournamentRounds.some((round: any) =>
        round?.location && this.details.locations.some((location: any) =>
          location?.name === round.location
        )
      );
      return hasMatchingLocation;
    }
    return false
  }

  teamRoundVisibleToLM(teamRound: any): boolean {

    if (!teamRound || !this.details || !this.details.locations) {
      return false;
    }
    // Ensure that teamRound.teamTournamentRounds is an array and has elements
    if (Array.isArray(teamRound.teamTournamentRounds) && teamRound.teamTournamentRounds.length > 0) {
      // Iterate over each teamTournamentRound
      for (let teamTournamentRound of teamRound.teamTournamentRounds) {
        // Check if the teamTournamentRound has a location and it matches any location in this.details.locations
        if (teamTournamentRound.location) {
          for (let location of this.details.locations) {
            if (location && location.name && teamTournamentRound.location === location.name) {
              return true; // Location matches, return true
            }
          }
        }
      }
    }

    // If no matching locations found, return false
    return false;
  }

  sortHoleScores(round: any): { key: number, value: any }[] {
    return Object.entries(round.roundScores)
      .map(([key, value]) => ({ key: +key, value }))
      .sort((a, b) => a.key - b.key);
  }

  sortTournamentRoundsByScore(individual: any): any[] {
    return individual.tournamentRounds.sort((a: { roundScoreTotal: number }, b: { roundScoreTotal: number }) => a.roundScoreTotal - b.roundScoreTotal);
  }

  getRoundScoresCount(individual: any): number {
    if (individual && individual.tournamentRounds && individual.tournamentRounds.roundScores) {
      return Object.keys(individual.tournamentRounds.roundScores).length;
    }
    return 0;
  }

  groupByPlayer(players: any[]): { [key: string]: any[] } {
    const grouped: { [key: string]: any[] } = {};

    players.forEach(player => {
      const email = player.playersEmail;
      if (!grouped[email]) {
        grouped[email] = [];
      }
      grouped[email].push(player);
    });

    // Sort rounds for each player by score
    for (let email in grouped) {
      grouped[email].sort((a, b) => a.golfRoundData.roundScoreTotal - b.golfRoundData.roundScoreTotal);
    }

    return grouped;
  }

  hasVerifiedScorecard(individual: any): boolean {
    const roundFound = individual.tournamentRounds.find((round: any) =>
      round.scorecardVerifiedBy !== '' && round.scorecardVerifiedBy !== null
    );

    return !!roundFound;  // This will return true if a round is found and false otherwise.
  }

  previousRoundsPlayedLocation(individual: any): any {
    const roundFound = individual.tournamentRounds.find((round: any) =>
      round.scorecardVerifiedBy !== '' && round.scorecardVerifiedBy !== null
    );
    return roundFound;  // This will return true if a round is found and false otherwise.
  }

  previousTeamRoundsPlayedLocation(team: any) {
    const roundFound = team.teamTournamentRounds.find((round: any) =>
      round.gameSessionId == team.verifiedGameSessionId
    );
    return roundFound;
  }

  openScorecardUploadScreen(round: any, individual: any) {
    //check here what type of round it is before moving on
    const request: ScorecardImageUploadRequest = {
      file: null,
      competitorId: individual.seriesCompetitorId,
      email: individual.playersEmail,
      tournamentRoundId: this.details.tournamentRoundRequest.id,
      referenceId: round.roundSyncId,
      teamCard: this.details.tournamentRoundRequest.isTeamEvent,
      userId: individual.userId,
      userSyncId: individual.playersSyncId,
      seriesTournamentId: this.details.tournamentRoundRequest.tournamentId,
      location: individual.playedLocation,
      seriesId: this.data.seriesId,
      simProvidedScore: round.simProvidedRoundScore
    }
    const dialogRef = this.dialog.open(ScorecardUploadComponent, {
      data: request
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading = false
        const individualCompetitor = this.details?.individualCompetitors?.find((competitor: any) => {
          return competitor?.tournamentRounds?.find((round: any) => round?.roundSyncId === result.roundSyncId);
        });
        if (individualCompetitor) {
          const roundFound = individualCompetitor.tournamentRounds.find((round: any) =>
            round.roundSyncId === request.referenceId
          );
          individualCompetitor.scorecardImageURL = result.scorecardImageURL
          individualCompetitor.roundSyncId = result.roundSyncId
          individualCompetitor.recognizedCompetitor = true
          individualCompetitor.userId = result.userId
          roundFound.scorecardImageURL = result.scorecardImageURL
          individualCompetitor.seriesCompetitorId = result.seriesCompetitorId
        }
        this.cd.markForCheck()
      }
    })
  }

  displayTeamUpload(rounds: any) {
    return rounds.some((round: any) => round.scorecardImageUrl != null);
  }

  createRoundManually(nuteam: any) {
    console.log(nuteam)
    const request: CreateTeamRoundRequest = {
      teamId: nuteam.teamId,
      seriesTournamentId: this.details.tournamentRoundRequest.tournamentId,
      tournamentRoundId: this.details.tournamentRoundRequest.id,
      location: nuteam.invitedFromLocation,
      emails: nuteam.memberEmails,
      userSyncIds: nuteam.teamMemberSyncIds,
      seriesId: this.data.seriesId,
      referenceId: 0,
      teamName: nuteam.teamName,
    }
    const dialogRef = this.dialog.open(ManualRoundEntryComponent, {
      data: request
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // if this is the tournament round you can just add it the teams data
        const teamfound = this.details?.seriesTournamentTeamRounds?.find((team: any) => {
          return team.teamMemberSyncIds.every((id: number) => nuteam.teamMemberSyncIds.includes(id));
        });
        if (teamfound) {
          const nuRound = {
            "gameSessionId": 0,
            "join": result.createdOn,
            "location": nuteam.invitedFromLocation,
            "scorecardImageUrl": result.scorecardImageURL,

          }
          teamfound.teamTournamentRounds.push(nuRound)
        }
      }
      this.cd.detectChanges()
      this.cd.markForCheck()
    })
  }

  openTeamScorecardUploadScreen(round: any, team: any) {
    const request: TeamScorecardImageUploadRequest = {
      file: null,
      competitorId: round.teamId,
      seriesTournamentId: this.details.tournamentRoundRequest.tournamentId,
      tournamentRoundId: this.details.tournamentRoundRequest.id,
      location: round.location,
      emails: team.memberEmails,
      userSyncIds: team.teamMemberSyncIds,
      seriesId: this.data.seriesId,
      simProvidedScore: round.simProvidedRoundScore,
      referenceId: round.gameSessionId,
      teamName: team.teamName,
      teamNameVariations: team.teamNameVariations
    }
    const dialogRef = this.dialog.open(TeamScorecardUploadComponent, {
      data: request
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading = false
        const foundround = this.details?.seriesTournamentTeamRounds?.find((team: any) => {
          return team?.teamTournamentRounds?.find((played: any) => played?.gameSessionId === round.gameSessionId);
        });
        if (foundround) {
          console.log("found round here", foundround)
          foundround.teamName = result.teamName
          foundround.teamId = result.seriesTournamentTeamId
          foundround.teamTournamentRounds.forEach((x: any) => {
            if (round.gameSessionId === x.gameSessionId) {
              x.scorecardImageUrl = result.scorecardImageURL
              x.teamId = result.seriesTournamentTeamId
            }
          });
        }
        this.cd.detectChanges()
        this.cd.markForCheck()
      }
    })
  }

  viewCard(individual: any, round: any) {
    const start = individual.scorecardVerified
    const request: ScorecardViewRequest = {
      scoreCards: [],
      gameSessionExternalKey: round.gameSessionExternalKey,
      scorecardImageURL: round.scorecardImageURL,
      competitorId: individual.userId,
      email: individual.playersEmail,
      tournamentRoundId: this.details.tournamentRoundRequest.id,
      referenceId: round.roundSyncId,
      teamCard: this.details.tournamentRoundRequest.isTeamEvent,
      userId: individual.userId,
      userSyncId: individual.playersSyncId,
      seriesTournamentId: this.details.tournamentRoundRequest.tournamentId,
      location: individual.playedLocation,
      seriesId: this.data.seriesId,
      course: this.details.course,
      roundScores: round?.roundScores || {},
      totalNumberOfStrokes: round?.roundScoreTotal || 0,
      created: round.created,
      netRoundOnly: this.details.netOnly,
      allStrokes: round?.allStrokes,
      roundVerified: individual.scorecardVerified,
      roundNetScore: round.roundNetScore,
      simProvidedScore: round.simProvidedRoundScore,
      startingHandicap: round.startingHandicap,
      overrideScore: individual.overrideScore,
      messageArea: round.messageArea,
      //this is the round that was submitted as part of the tournament round
      advancingRoundSyncId: individual.roundSyncId,
      displayName: individual.displayName,
      finalRound: this.details.isFinalRound
    };

    this.sessionService.getGameSessionScoreCards(request.gameSessionExternalKey)
      .subscribe(response => {
      // console.log("response: ", JSON.stringify(response, null, 2));
      const scoreCards = response.length > 0 ? response : [{ url: "/lib/angular/dist/assets/xga-logo.jpg" }];
      // console.log("scorecards: ", JSON.stringify(scoreCards, null, 2));
      request.scoreCards = scoreCards;
      // console.log("request.scorecards: ", JSON.stringify(request.scoreCards, null, 2));
      const dialogRef = this.dialog.open(RoundRecapComponent, {
        data: request,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const individualCompetitor = this.details?.individualCompetitors?.find((competitor: any) => {
            return competitor?.tournamentRounds?.find((round: any) => round?.roundSyncId === result.referenceId);
          });
          //check for update by saying: if we starting with not verified and then got verified we need to update that individual info
          if (start == false && result.roundVerified) {
            individualCompetitor.roundSyncId = request.referenceId
            individualCompetitor.seriesCompetitorId = result.competitorId
            individualCompetitor.scorecardVerified = result.roundVerified
            individualCompetitor.simProvidedScore = result.simProvidedScore
            individualCompetitor.startingHandicap = result.startingHandicap
            individualCompetitor.overrideScore = result.overrideScore
            individualCompetitor.overrideScoreMessage = result.messageArea
            individualCompetitor.netScore = result.roundNetScore
            if (individualCompetitor) {
              const roundFound = individualCompetitor.tournamentRounds.find((round: any) =>
                round.roundSyncId === request.referenceId
              );
              if (roundFound) {
                roundFound.roundNetScore = result.roundNetScore
                roundFound.roundGrossScore = result.roundGrossScore
                roundFound.messageArea = result.messageArea
                roundFound.startingHandicap = result.startingHandicap
                roundFound.simProvidedRoundScore = result.simProvidedScore
                roundFound.scorecardImageURL = result.scorecardImageURL
                roundFound.scorecardVerifiedBy = "justverifieidamomentagonotrequiredrightnow"
                roundFound.scorecardVerifiedOn = new Date()
              }
            }

          }

          this.isLoading = false
          this.cd.markForCheck()
        }
      })
    })
  }

  viewTeamCard(team: any, round: any) {
    // console.log("team here", team)
    // console.log("type of game is ", this.data.gameMode)
    const request: TeamScorecardViewRequest = {
        scoreCards: [],
        gameSessionExternalKey: round.gameSessionExternalKey,
        scorecardImageURL: round.scorecardImageUrl,
        emails: team.memberEmails,
        userSyncIds: team.teamMemberSyncIds,
        created: round.join,
        simProvidedScore: round.simProvidedRoundScore,
        referenceId: round.gameSessionId,
        seriesTournamentId: this.details.tournamentRoundRequest.tournamentId,
        tournamentRoundId: this.details.tournamentRoundRequest.id,
        course: this.details.course,
        location: round.location,
        teamName: team.teamName != null ? team.teamName : team.teamNameVariations,
        roundScores: round.roundScores,
        roundSyncId: this.details.tournamentRoundRequest.syncId,
        teamId: team.teamId,
        roundGrossScore: round.roundGrossScore,
        eligibleForManual: team.eligibleForManual,
        roundVerified: team.scoreCardVerified,
        gameMode: this.data.gameMode,
        membersIdsToEmails: team.membersIdsToEmails
    }
    this.sessionService.getGameSessionScoreCards(request.gameSessionExternalKey)
      .subscribe(response => {
      // // console.log("response: ", JSON.stringify(response, null, 2));
      const scoreCards = response.length > 0 ? response : [{ url: "/lib/angular/dist/assets/xga-logo.jpg" }];
      // // console.log("scorecards: ", JSON.stringify(scoreCards, null, 2));
      request.scoreCards = scoreCards;
      // console.log("request.scorecards: ", JSON.stringify(request.scoreCards, null, 2));
      const dialogRef = this.dialog.open(RoundRecapComponent, {
        data: request,
      });
      dialogRef.afterClosed().subscribe(x => {
        this.isLoading = false
        const foundteam = this.details?.seriesTournamentTeamRounds?.find((team: any) => {
          return team?.teamTournamentRounds?.find((played: any) => played?.gameSessionId === round.gameSessionId && team.teamId == x.teamId)
        })
        if (!foundteam) {
          //its not by game session because it was manually uploaded.
          var secondSearch = this.details?.seriesTournamentTeamRounds.find((x: any) => x.teamId == team.teamId)
          if (secondSearch) {
            secondSearch.scoreCardVerified = x.roundVerified
            const onlyManualUpload = secondSearch.teamTournamentRounds?.find((r: any) => r.gameSessionId === 0)
            if (onlyManualUpload) {

            }
          }
        }
        if (foundteam) {
          foundteam.teamName = x.teamName
          foundteam.scoreCardVerified = x.roundVerified
          if (foundteam.eligibleForManual) {
            foundteam.teamTournamentRounds[0].roundGrossScore = x.roundGrossScore
          } else {
            const foundround = foundteam.teamTournamentRounds.find((round: any) =>
              round.roundSyncId === round.gameSessionId)
            if (foundround) {
              //foundround.scorecardImageUrl = x.scorecardImageURL

            }
          }

        }
        this.cd.detectChanges()
        this.cd.markForCheck()
      }, err => { console.log(err) }, () => { })
    })
  }

  calculateCardScore(card: any): number {
    let total = 0;
    for (let key in card) {
      if (card.hasOwnProperty(key)) {
        // Ensure the values are numbers before adding to the total
        const value = card[key];
        if (typeof value === 'number') {
          total += value;
        } else {
          console.warn(`Unexpected value type for key ${key}: ${value}`);
        }
      }
    }
    return total;
  }

  calculateTotalBestBallScore(card: any): number {
    // console.log("card here ", card);
    const totalScore = this.calculateCardScore(card);
    // console.log("Total score: ", totalScore); // Debugging log
    return totalScore;
  }

  openTeamAdvancementScreen(team: any, round: any) {
    var previousRound = this.previousTeamRoundsPlayedLocation(team)
    this.isLoading = true
    const hasNetEvent = round.nextTournaments?.find((r: any) => r.netOnly === true)
    if (hasNetEvent) {

      console.log("spill the team", team)

      console.log("spill the round", round)

      const compets: SeriesTournamentTeamCompetitorRound[] = []
      team.membersIdsToEmails.forEach((dict: { [key: number]: string }) => {
        Object.keys(dict).forEach((key) => {
          const email = dict[parseInt(key)];
          const memberEmailPair: SeriesTournamentTeamCompetitorRound = {
            seriesCompetitorId: parseInt(key),
            email: email,
            displayName: '',
            handicap: 0,
            roundSyncId: 0,
            playerSyncId: parseInt(key)
          };
          compets.push(memberEmailPair);
        });
      })


      const bb_request: BestBall_TeamAdvancementRequest = {
        seriesTournamentId: this.data.tournamentId,
        team: team,
        nextRounds: this.data.nextRounds,
        approvingAdvance: '',
        pastRoundsLocation: previousRound.location,
        competitors: compets,
        seriesId: this.data.seriesId
      }
      const dialogRef = this.dialog.open(TeamBestBallAdvancementConfirmComponent, {
        data: bb_request
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isLoading = false
          this._snackBar.open('Team advanced.', '', {
            duration: 3000,
            verticalPosition: "bottom",
            horizontalPosition: "right",
            panelClass: "successNotice"
          });
          const foundTeam = this.details?.seriesTournamentTeamRounds?.find((team: any) => {
            return team?.teamTournamentRounds?.find((played: any) => played?.gameSessionId === previousRound.gameSessionId);
          });
          if (foundTeam) {
            foundTeam.advancing = result
          }

          this.cd.detectChanges()
          this.cd.markForCheck()
        }
      }, err => {
        this.isLoading = false
        console.log(err)
        this._snackBar.open('Error Occured. Please try again. If issue continues please contact support.', '', {
          duration: 3000,
          verticalPosition: "top",
          horizontalPosition: "center",
          panelClass: "failureNotice"
        });

      }, () => { })

    } else {

      // left off here   you added the reference id tat you will pull back to check and see if a round can advance.
      const request: TeamAdvancementRequest = {
        team: team,
        nextRounds: this.data.nextRounds,
        tournamentId: this.details.tournamentRoundRequest.tournamentId,
        pastRoundPlayedLocation: previousRound.location
      }
      const dialogRef = this.dialog.open(TeamAdvancementConfirmComponent, {
        data: request
      })

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isLoading = false
          this._snackBar.open('Team advanced.', '', {
            duration: 3000,
            verticalPosition: "bottom",
            horizontalPosition: "right",
            panelClass: "successNotice"
          });
          const foundTeam = this.details?.seriesTournamentTeamRounds?.find((team: any) => {
            return team?.teamTournamentRounds?.find((played: any) => played?.gameSessionId === previousRound.gameSessionId);
          });
          if (foundTeam) {
            foundTeam.advancing = result
          }

          this.cd.detectChanges()
          this.cd.markForCheck()
        }
      }, err => {
        this.isLoading = false
        console.log(err)
        this._snackBar.open('Error Occured. Please try again. If issue continues please contact support.', '', {
          duration: 3000,
          verticalPosition: "top",
          horizontalPosition: "center",
          panelClass: "failureNotice"
        });

      }, () => { })
    }
  }

  openAdvancementScreen(individual: any) {
    var previousRound = this.previousRoundsPlayedLocation(individual)
    const request: TournamentAdvancementRequest = {
      individual: individual,
      nextRounds: this.data.nextRounds,
      tournamentId: this.details.tournamentRoundRequest.tournamentId,
      pastRoundPlayedLocation: previousRound.location
    }
    const dialogRef = this.dialog.open(AdvanceConfirmComponent, {
      data: request
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const individualCompetitor = this.details?.individualCompetitors?.find((x: any) => {
          return x.seriesCompetitorId === individual.seriesCompetitorId;
        });
        if (individualCompetitor) {
          individualCompetitor.advancing = true

        }
        this.cd.markForCheck()
      }
    }, err => { console.log(err) }, () => { })
  }

  openComms(email: string) {

    var request: DirectCommunicationRequest = {
      emailTo: email,
      emailFrom: '',
      message: ''
    }

    this.dialog.open(CommunicationComponent, {
      data: request
    })
  }

  convertHandicap(handicap: number): string {
    // Your logic to convert the handicap here, for instance:
    return handicap < 0 ? `+${-handicap}` : handicap.toString();
  }


  closeRound(round: any) {
    // Show confirmation alert
    if (!window.confirm('Are you sure you want to close this round?')) {
      return;
    }
    this.isLoading = true;
    const request: CloseRoundRequest = {
      tournamentId: round.tournamentId,
      roundId: round.id,
      isFinal: round.isFinal,
      userMakingRequest: '',
      isTeamEvent: round.isTeamEvent
    }
    this.roundService.postCloseRound(request).subscribe(x => {
      if (x) {
        this.details.tournamentRoundRequest.closed = x
        this._snackBar.open('Thats A Wrap! Round Has Been Closed!.', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "successNotice"
        });
        this.isLoading = false
      }
    }, err => { console.log(err) }, () => { })

  }

  publishLeaderboard() {
    const request: LeaderboardOnRequest = {
      tournamentId: this.data.tournamentId
    }
    this.service.postPublishLeaderboard(request).subscribe(x => {
      //this.details.tournamentRoundRequest.display
    }, err => { console.log(err) }, () => { })
  }

}

